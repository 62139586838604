export const updateUser = (userDetails) => {
  return {
    type: "UPDATE_USER_DETAILS",
    payload: userDetails,
  };
};

export const updateCifId = (Id) => {
  return {
    type: "UPDATE_CIF_ID",
    payload: Id,
  };
};
